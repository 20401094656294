import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class WalletService {
  private walletAddressSubject: BehaviorSubject<string | null>;
  public walletAddress$: Observable<string | null>;

  constructor(private router: Router) {
    const walletAddress = localStorage.getItem('walletAddress');
    this.walletAddressSubject = new BehaviorSubject<string | null>(
      walletAddress,
    );
    this.walletAddress$ = this.walletAddressSubject.asObservable();
  }

  /* ------------------------------- Wallet address ------------------------------- */

  isWalletAddress(): boolean {
    return this.getWalletAddress() !== null;
  }

  setWalletAddress(walletAddress: string | null): void {
    this.walletAddressSubject.next(walletAddress);
  }

  getWalletAddress(): string | null {
    return this.walletAddressSubject.getValue();
  }

  /* ------------------------------- Wallet name ------------------------------- */

  isWalletName(): boolean {
    return this.getWalletName() !== null;
  }

  getWalletName(): string | null {
    return localStorage.getItem('walletName');
  }

  /* ------------------------------- Wallet token ------------------------------- */

  isWalletToken(): boolean {
    return this.getWalletToken() !== null;
  }

  getWalletToken(): string | null {
    return localStorage.getItem('walletAuthToken');
  }

  /* ------------------------------- Disconnect wallet ------------------------------- */

  disconnectWallet(): void {
    localStorage.removeItem('walletAddress');
    localStorage.removeItem('walletAuthToken');
    localStorage.removeItem('walletName');

    const currentUrl = window.location.pathname;
    if (currentUrl !== '/' && currentUrl !== '/terms-and-conditions') {
      this.router.navigate(['/']);
    }

    this.setWalletAddress('');
  }
}
