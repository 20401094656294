import { Injectable, NgZone } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NavigationStart, Router } from '@angular/router';
import { SnackBarComponent } from '../../shared/modals/snack-bar/snack-bar.component';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  constructor(
    private snackBar: MatSnackBar,
    private zone: NgZone,
    router: Router,
  ) {
    router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        snackBar.dismiss();
      }
    });
  }

  info(message?: string): void {
    this.zone.run(() => {
      this.snackBar.openFromComponent(SnackBarComponent, {
        verticalPosition: 'bottom',
        horizontalPosition: 'center',
        // duration: 6000,
        panelClass: 'info-snack-bar',
        announcementMessage: message || 'Success',
        data: {
          type: 'info',
        },
      });
    });
  }

  success(message?: string): void {
    this.zone.run(() => {
      this.snackBar.openFromComponent(SnackBarComponent, {
        verticalPosition: 'bottom',
        horizontalPosition: 'center',
        // duration: 6000,
        panelClass: 'success-snack-bar',
        announcementMessage: message || 'Success',
        data: {
          type: 'success',
        },
      });
    });
  }

  error(message?: string): void {
    this.zone.run(() => {
      this.snackBar.openFromComponent(SnackBarComponent, {
        verticalPosition: 'bottom',
        horizontalPosition: 'center',
        // duration: 6000,
        panelClass: 'error-snack-bar',
        announcementMessage: message || 'Something went wrong',
        data: {
          type: 'error',
        },
      });
    });
  }
}
