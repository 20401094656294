export class GetProducts {
  static readonly type = '[Core] Get products';
  constructor() {}
}

export class CreateCustomer {
  static readonly type = '[Core] Create customer';
  constructor(
    public login: string,
    public walletAddress: string,
    public password: string,
  ) {}
}

export class CreateOrder {
  static readonly type = '[Core] Create order';
  constructor(public products: { product_id: string; count: number }[]) {}
}

export class StatusOrder {
  static readonly type = '[Core] Status order';
  constructor(public orderId: string) {}
}

export class PaymentCardano {
  static readonly type = '[Core] Payment cardano';
  constructor(public orderId: string) {}
}

export class SetProductNfts {
  static readonly type = '[Core] Set product nfts';
  constructor(public productNfts: { product_id: string; count: number }[]) {}
}

export class SetTotalPrice {
  static readonly type = '[Core] Set total price';
  constructor(public totalPrice: number) {}
}
