export interface CoreStateModel {
  productNfts: { product_id: string; count: number }[];
  totalPrice: number;
}

export interface GetProductsResponse {
  status: boolean;
  total: number;
  products: Product[];
}

export interface Product {
  name: string;
  price: number;
  inventory: number;
  image: string;
  product_id: string;
}

export interface CreateCustomerResponse {
  status: boolean;
  customer_id: string;
  token: string;
}

export interface CreateOrderResponse {
  status: boolean;
  order_id: string;
}

export interface StatusOrderResponse {
  status: boolean;
  order_status: string;
  postprocessing: {
    status: string,
    cardano_asset: {
      status: string;
      data: {
        hash: string;
      }
    }
  },
}

export interface PaymentCardanoResponse {
  status: boolean;
  data: {
    metadata: {
      validator: string;
      payment_id: string;
    };
    receiver_address: string;
  };
}
