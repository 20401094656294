import { Action, State, StateContext } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { WalletStateModel } from './wallet-state.model';
import { EternlConnect, NamiConnect } from './wallet.actions';
import { BrowserWallet } from '@meshsdk/core';
import { NotificationService } from '../../services/notification.service';
import { WalletService } from '../../services/wallet.service';

@State<WalletStateModel>({
  name: 'wallet',
})
@Injectable()
export class WalletState {
  constructor(
    private notification: NotificationService,
    private walletService: WalletService,
  ) {}

  @Action(EternlConnect)
  async eternlConnect(
    {}: StateContext<WalletStateModel>,
    {}: EternlConnect,
  ): Promise<void> {
    try {
      const wallet = await BrowserWallet.enable('eternl');
      const address = await wallet.getChangeAddress();
      this.walletService.setWalletAddress(address);
      localStorage.setItem('walletAddress', address);
      localStorage.setItem('walletName', 'eternl');
    } catch (error) {
      this.notification.error(
        'Eternl extension not found. Please, install it.',
      );
    }
  }

  @Action(NamiConnect)
  async namiConnect(
    {}: StateContext<WalletStateModel>,
    {}: NamiConnect,
  ): Promise<void> {
    try {
      const wallet = await BrowserWallet.enable('nami');
      const address = await wallet.getChangeAddress();
      this.walletService.setWalletAddress(address);
      localStorage.setItem('walletAddress', address);
      localStorage.setItem('walletName', 'nami');
    } catch (error) {
      this.notification.error(
        'Eternl extension not found. Please, install it.',
      );
    }
  }
}
