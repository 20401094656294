<div class="snack-bar">
  <img *ngIf="type === 'success'" src="" alt="success" />
  <!--  <img-->
  <!--    *ngIf="type === 'error'"-->
  <!--    src="assets/images/icons/icon-error.svg"-->
  <!--    alt="error"-->
  <!--  />-->
  <p>{{ message }}</p>
  <span (click)="snackBarRef.dismissWithAction()" class="snack-bar__close">
    <img src="/assets/images/icons/icon-close.svg" alt="" />
  </span>
</div>
