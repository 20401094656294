import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import {
  CreateCustomerResponse,
  CreateOrderResponse,
  GetProductsResponse,
  PaymentCardanoResponse, StatusOrderResponse,
} from '../store/core';

@Injectable({
  providedIn: 'root',
})
export class CoreService {
  constructor(private http: HttpClient) {}

  getProducts(): Observable<GetProductsResponse> {
    return this.http.get<GetProductsResponse>(
      `${environment.apiUrl}/products/${environment.botId}`,
    );
  }

  createCustomer(
    login: string,
    walletAddress: string,
    password: string,
  ): Observable<CreateCustomerResponse> {
    return this.http.post<CreateCustomerResponse>(
      `${environment.apiUrl}/customer/create/${environment.botId}`,
      {
        login: login,
        password: password,
        profile: {
          name: walletAddress,
          cardano_wallet_address: walletAddress,
        },
      },
    );
  }

  createOrder(
    products: { product_id: string; count: number }[],
  ): Observable<CreateOrderResponse> {
    const token = localStorage.getItem('walletAuthToken');

    return this.http.post<CreateOrderResponse>(
      `${environment.apiUrl}/api/order`,
      {
        products: products,
      },
      {
        headers: {
          'X-Access-Token': token || '',
        },
      },
    );
  }

  statusOrder(orderId: string): Observable<StatusOrderResponse> {
    const token = localStorage.getItem('walletAuthToken');

    return this.http.get<StatusOrderResponse>(
      `${environment.apiUrl}/api/order/${orderId}`,
      {
        headers: {
          'X-Access-Token': token || '',
        }
      }
    );
  }

  paymentCardano(orderId: string): Observable<PaymentCardanoResponse> {
    const token = localStorage.getItem('walletAuthToken');

    return this.http.post<PaymentCardanoResponse>(
      `${environment.apiUrl}/api/customer/payment/prepare/cardano`,
      {
        order_id: orderId,
      },
      {
        headers: {
          'X-Access-Token': token || '',
        },
      },
    );
  }
}
