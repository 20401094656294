import { Component, inject, OnInit } from '@angular/core';
import { MatSnackBarRef } from '@angular/material/snack-bar';

@Component({
  selector: 'app-snack-bar',
  templateUrl: './snack-bar.component.html',
  styleUrls: ['./snack-bar.component.scss'],
})
export class SnackBarComponent implements OnInit {
  snackBarRef = inject(MatSnackBarRef);
  message =
    this.snackBarRef.containerInstance.snackBarConfig.announcementMessage;
  type: 'error' | 'success' =
    this.snackBarRef.containerInstance.snackBarConfig.data.type;

  constructor() {}

  ngOnInit(): void {}
}
