import { Selector } from '@ngxs/store';
import { CoreState } from './core.state';
import {
  CoreStateModel,
  CreateOrderResponse,
  GetProductsResponse,
  PaymentCardanoResponse,
  Product,
  StatusOrderResponse,
} from './core-state.model';

export class CoreSelectors {
  @Selector([CoreState])
  static products(state: GetProductsResponse): Product[] {
    return state.products;
  }

  @Selector([CoreState])
  static order_id(state: CreateOrderResponse): string {
    return state.order_id;
  }

  @Selector([CoreState])
  static receiver_address(state: PaymentCardanoResponse): string {
    return state.data.receiver_address;
  }

  @Selector([CoreState])
  static metadata(state: PaymentCardanoResponse): {
    validator: string;
    payment_id: string;
  } {
    return state.data.metadata;
  }

  @Selector([CoreState])
  static order_status(state: StatusOrderResponse): string {
    return state.order_status;
  }

  @Selector([CoreState])
  static postprocessing(state: StatusOrderResponse): {
    status: string;
    cardano_asset: {
      status: string;
      data: {
        hash: string;
      };
    };
  } {
    return state.postprocessing;
  }

  @Selector([CoreState])
  static productNfts(
    state: CoreStateModel,
  ): { product_id: string; count: number }[] {
    return state.productNfts;
  }

  @Selector([CoreState])
  static totalPrice(state: CoreStateModel): number {
    return state.totalPrice;
  }
}
