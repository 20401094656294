import { Action, State, StateContext, Store } from '@ngxs/store';
import { Injectable } from '@angular/core';
import {
  CoreStateModel,
  CreateCustomerResponse,
  CreateOrderResponse,
  GetProductsResponse,
  PaymentCardanoResponse,
  StatusOrderResponse,
} from './core-state.model';
import {
  CreateCustomer,
  CreateOrder,
  GetProducts,
  PaymentCardano,
  SetProductNfts,
  SetTotalPrice,
  StatusOrder,
} from './core.actions';
import { CoreService } from '../../services/core.service';
import { Observable, tap } from 'rxjs';

@State<CoreStateModel>({
  name: 'core',
})
@Injectable()
export class CoreState {
  constructor(
    private coreService: CoreService,
    private store: Store,
  ) {}

  @Action(GetProducts)
  getProducts(
    { patchState }: StateContext<GetProductsResponse>,
    {}: GetProducts,
  ): Observable<GetProductsResponse> {
    return this.coreService.getProducts().pipe(
      tap((res): void => {
        if (res.status) {
          patchState({
            products: res.products,
          });
        }
      }),
    );
  }

  @Action(CreateCustomer)
  createCustomer(
    { patchState }: StateContext<CoreStateModel>,
    { login, walletAddress, password }: CreateCustomer,
  ): Observable<CreateCustomerResponse> {
    return this.coreService.createCustomer(login, walletAddress, password).pipe(
      tap((res) => {
        if (res.status) {
          localStorage.setItem('walletAuthToken', res.token);
        }
      }),
    );
  }

  @Action(CreateOrder)
  createOrder(
    { patchState }: StateContext<CreateOrderResponse>,
    { products }: CreateOrder,
  ): Observable<CreateOrderResponse> {
    return this.coreService.createOrder(products).pipe(
      tap((res) => {
        if (res.status) {
          patchState({
            order_id: res.order_id,
          });
        }
      }),
    );
  }

  @Action(StatusOrder)
  statusOrder(
    { patchState }: StateContext<StatusOrderResponse>,
    { orderId }: StatusOrder,
  ): Observable<StatusOrderResponse> {
    return this.coreService.statusOrder(orderId).pipe(
      tap((res) => {
        if (res.status) {
          patchState({
            order_status: res.order_status,
            postprocessing: res.postprocessing,
          });
        }
      }),
    );
  }

  @Action(PaymentCardano)
  paymentCardano(
    { patchState }: StateContext<PaymentCardanoResponse>,
    { orderId }: PaymentCardano,
  ): Observable<PaymentCardanoResponse> {
    return this.coreService.paymentCardano(orderId).pipe(
      tap((res) => {
        if (res.status) {
          patchState({
            data: res.data,
          });
        }
      }),
    );
  }

  @Action(SetProductNfts)
  setProductNfts(
    { patchState }: StateContext<CoreStateModel>,
    { productNfts }: SetProductNfts,
  ): void {
    patchState({
      productNfts: productNfts,
    });
  }

  @Action(SetTotalPrice)
  setTotalPrice(
    { patchState }: StateContext<CoreStateModel>,
    { totalPrice }: SetTotalPrice,
  ): void {
    patchState({
      totalPrice: totalPrice,
    });
  }
}
