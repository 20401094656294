import { NgModule } from '@angular/core';
import { NgxsModule } from '@ngxs/store';
import { CoreState } from './store/core';
import { WalletState } from './store/wallet';
import { HttpClientModule } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { AuthGuard } from './guards/auth.guard';
import { MatSnackBarModule } from '@angular/material/snack-bar';

@NgModule({
  declarations: [],
  imports: [
    HttpClientModule,
    MatSnackBarModule,
    NgxsModule.forRoot([CoreState, WalletState], {
      developmentMode: !environment.production,
    }),
  ],
  providers: [AuthGuard],
})
export class CoreModule {}
